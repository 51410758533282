<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col class="text-center text-h4 pa-5">
                Users
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-lg-end text-center">
                <v-btn color="primary" lg="3" class="px-10" @click="$router.push('/user/create')">
                  Create new user
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="fields" :items="userList" :search="search" hide-default-footer mobile-breakpoint="0" :loading="loading" loading-text="Loading Data ...">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small color="success" class="pa-0 ma-1" @click="$router.push(`user/edit/${item.id}`)">
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn small color="error" class="pa-0 ma-1" @click="showDialog(item)">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`top`]>
                    <v-dialog v-model="showdialog" max-width="500px">
                      <v-card class="pa-5">
                        <v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" class="px-5 mr-2" @click="showdialog=false">Cancel</v-btn>
                          <v-btn color="primary" class="px-5" @click="deletePeople()">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="test-center mb-5">
                <v-pagination v-model="current_page" :length="total_pages" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination> 
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_users_list,delete_user} from '@api/users'
export default {
  data(){
    return {
      fields:[
          {
            text:"Name",
            value:"name",
          },
          {
            text:"Contact",
            value:"contact_number",
          },
          {
            text:'Email',
            value:'email'
          },
          { 
            text:"Actions",
            value:"actions",
            align:"center",
          }
        ],
        userList:[],
        search:'',
        showdialog: false,
        person_id:"",
        current_page: 1,
        total_pages: 1,
        loading: false,
        errResp:'',
    }
  },
  mounted(){
    this.getUserList();
  },

  methods: {
    currentPage(){
      var p=Number(this.current_page)
      this.getUserList({page:p})
    },
    getUserList(data){
        this.loading=true;
        this.errResp='';
        get_users_list(data).then(resp=>{
            this.loading=false;
            if(resp.status === 'Success'){
                this.userList=resp.data.data;
                this.total_pages= resp.data.last_page;
                this.current_page= resp.data.current_page;
            }else{
                this.errResp=resp.errors
            }
        })
    },
    showDialog(item){
      this.person_id = item.id;
      this.showdialog=true;
    },
    deletePeople(){
    //   this.deletepeople({vm:this,id:this.person_id});
        delete_user(this.person_id).then(resp=>{
            if(resp.status === 'Success'){
                this.$toastr.s(resp.message);
                this.showdialog=false;
                let p=Number(this.current_page);
                this.getUserList({page:p});
            }else{
                this.$toastr.e(resp.errors);
            }           
        })
    }
  }
}
</script>

<style>

</style>
