import http from '@util/http';
export async function get_users_list(data){
    return (await http.get('/user/list',{params:data})).data
}
export async function create_user(data){
    return (await http.post('/user/create',data)).data
}
export async function delete_user(id){
    return (await http.post(`/user/delete/${id}`)).data
}
export async function get_user_details(id){
    return (await http.get(`/user/edit/${id}`)).data
}
export async function update_user(data){
    return (await http.post(`/user/update/${data.id}`,data.data)).data
}
export async function get_all_users(){
    return (await http.get('/user/get/all/users')).data
}